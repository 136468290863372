import React from 'react';
import { Nav } from "react-bootstrap";

const CoreValue = () => {
  return (
    <>
      <section class="service_section layout_padding">
        <div class="service_container">
          <div class="container ">
            <div class="heading_container heading_center">
              <h2>
                Our Core <span>Values</span>
              </h2>
            </div>
            <div class="row">
              <div class="col-md-4 ">
                <div class="box ">
                  <div class="img-box">
                    <img src="images/s1.png" alt="Innovation" />
                  </div>
                  <div class="detail-box">
                    <h5>Innovation</h5>
                    <p>
                    We value innovation and constant progress, creating cutting-edge concepts, tools, and methods to bring about revolutionary change. <br /><br/>
                    </p>
                    <Nav.Link href="/about">Read More</Nav.Link>
                  </div>
                </div>
              </div>
              <div class="col-md-4 ">
                <div class="box ">
                  <div class="img-box">
                    <img src="images/colaboration.png" alt="Collaboration" />
                  </div>
                  <div class="detail-box">
                    <h5>Collaboration</h5>
                    <p>
                    We collaborate closely with customers, partners, and stakeholders to co-create solutions and achieve shared success because we believe in the power of partnerships.
                    </p>
                    <Nav.Link href="/about">Read More</Nav.Link>
                  </div>
                </div>
              </div>
              <div class="col-md-4 ">
                <div class="box ">
                  <div class="img-box">
                    <img src="images/integrity.png" alt="Integrity" />
                  </div>
                  <div class="detail-box">
                    <h5>Integrity</h5>
                    <p>
                    In all of our interactions and business activities, we embrace the highest ethical standards, creating openness, accountability, and trust. <br /><br />
                    </p>
                    <Nav.Link href="/about">Read More</Nav.Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-box">
              <Nav.Link href="/about">
                View All
              </Nav.Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CoreValue
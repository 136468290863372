import React from "react";
import { Nav } from "react-bootstrap";

const Aboutsection = () => {
  return (
    <>
      <section class="about_section layout_padding">
        <div class="container  ">
          <div class="heading_container heading_center">
            <h2>
              About <span>Us</span>
            </h2>
          </div>
          <div class="row">
            <div class="col-md-6 ">
              <div class="img-box">
                <img src="images/ab1.png" alt="" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="detail-box">
                <h3>We Are G & 4D's Digital Concepts Limited</h3>
                <p>
                G&4D's Digital Concepts Limited is an Indigenous company that is officially incorporated with the Corporate Affairs Commission (CAC) to aid other businesses by providing personalized business support; we are proudly African, and our digital services are remarkable.
                </p>
                <p>
                Our primary responsibility is to assist businesses in embracing the digital era, utilizing cutting-edge technologies, and streamlining operations to increase productivity and competitiveness in the marketplace; we facilitate the organic growth of other businesses throughout their digital lifecycles.
                </p>
                <Nav.Link className="btn-link" href="/about#read_about2">
                  Read More
                </Nav.Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aboutsection;
